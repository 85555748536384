// import Swiper JS
import Swiper, {Pagination, Autoplay} from 'swiper';
//import AOS animations
import AOS from 'aos';

// import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

//import AOS styles
import 'aos/dist/aos.css';

//import smooth scroll for safari
import * as smoothscroll from 'smoothscroll-polyfill';


// configure Swiper to use modules
Swiper.use([Pagination, Autoplay]);

document.addEventListener("DOMContentLoaded", () => {

    //init lightbox
    require('fslightbox');
    //init AOS
    AOS.init();
    //init smooth scroll
    smoothscroll.polyfill();

    const mobileSlider = new Swiper('.mobile-slider', {
        a11y: true,
        loop: false,
        speed: 800,
        slidesPerView: 'auto',
        spaceBetween: 15,

        breakpoints: {
            768: {
                spaceBetween: 20,
            },

            992: {
                slidesPerView: 3,
                spaceBetween: 0,
                loop: false,
            }
        },

        autoplay: {
            delay: 3000,
        },

    });

    const testimonialsSlider = new Swiper('.testimonials-slider', {
        a11y: true,
        loop: false,
        speed: 800,
        slidesPerView: 'auto',
        spaceBetween: 15,

        breakpoints: {
            768: {
                spaceBetween: 20,
            },

            992: {
                spaceBetween: 30,
            }
        },

        autoplay: {
            delay: 3000,
        },

        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },

    });

    /*Mobile Toggle*/

    const mobileToggle = document.querySelector('.mobile-toggle');
    const navigation = document.querySelector('.main-navigation');
    const body = document.querySelector('body');
    const navItems = document.querySelectorAll('.main-navigation__item');

    mobileToggle.addEventListener('click', openMenu);
    navItems.forEach(el => el.addEventListener('click', openMenu));

    function openMenu() {
        if (window.matchMedia("(max-width: 991px)").matches) {
            mobileToggle.classList.toggle('open');
            navigation.classList.toggle('open');
            body.classList.toggle('stop-scrolling');
        } else return;
    }

    /*Full Height Scroll*/

    const mouseIcon = document.querySelector('.mouse-icon-container');
    mouseIcon.addEventListener('click', scrollDown);

    function scrollDown() {
        window.scrollTo({
            top: window.innerHeight - 120,
            behavior: "smooth"
        });
    }

    /*Navigation*/

    let nav = document.querySelectorAll('.main-navigation__item');

    nav.forEach(el => el.addEventListener('click', navClick));


    function navClick(e) {
        e.preventDefault();
        let id = e.target.getAttribute('href');
        window.removeEventListener('scroll', underlineNavVisibleSection);
        document.querySelectorAll('.active-link').forEach(el => el.classList.remove('active-link'));
        e.target.classList.add('active-link');
        let distance = document.querySelector(id).offsetTop;

        if (id === '#nav-1' && window.matchMedia("(max-width: 991px)").matches) {
            window.scrollTo({
                top: distance - 100,
                behavior: "smooth",
            });
        } else if (id === '#nav-1' && window.matchMedia("(min-width: 992px)").matches) {
            window.scrollTo({
                top: distance - 10,
                behavior: "smooth",
            });
        } else {
            window.scrollTo({
                top: distance - 60,
                behavior: "smooth",
            });
        }

        setTimeout(underlineNavVisibleSectionTimeOut, 700);
    }


    //Make nav item active when section in visible

    let section = document.querySelectorAll("[id^='nav-']");
    let sections = {};
    let i = 0;

    Array.prototype.forEach.call(section, function (e) {
        sections[e.id] = e.offsetTop;
    });


    window.addEventListener('scroll', underlineNavVisibleSection);


    function underlineNavVisibleSection() {


        if (pageYOffset < 500) {
            document.querySelectorAll('.active-link').forEach(el => el.classList.remove('active-link'));
            return;
        }

        if (pageYOffset + window.innerHeight >= document.body.clientHeight) {
            document.querySelectorAll('.active-link').forEach(el => el.classList.remove('active-link'));
            document.querySelector(`header a[href='#nav-4']`).classList.add('active-link');
            return;
        }


        let scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;

        for (i in sections) {
            if (sections[i] <= (scrollPosition + 100)) {
                if (document.querySelector('.active-link'))
                    document.querySelectorAll('.active-link').forEach(el => el.classList.remove('active-link'));
                document.querySelector(`header a[href='#${i}']`).classList.add('active-link');
            }
        }
        if (sections['anchor-0'] > (scrollPosition + 100)) {
            if (document.querySelector('.active-link') != undefined)
                document.querySelectorAll('.active-link').forEach(el => el.classList.remove('active-link'));
        }
    }

    function underlineNavVisibleSectionTimeOut() {
        window.addEventListener('scroll', underlineNavVisibleSection);
    }

    /*Progress bar on top and header changing on scroll*/

    window.addEventListener("scroll", progressBar);

    let header = document.querySelector('header');

    function progressBar() {
        let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let scrolled = (winScroll / height) * 100;
        document.getElementById("progressBar").style.width = scrolled + "%";
        if (window.pageYOffset < 50 && header.classList.contains('scroll')) {
            header.classList.remove('scroll');
        }
        if (window.pageYOffset >= 50 && !header.classList.contains('scroll')) {
            header.classList.add('scroll');
        }
    }



    /*Impressum Datenschutz*/

    let privacyLink = document.querySelectorAll('.imprint');

    const openBlock = (element) => {
        let block = document.querySelector(element.getAttribute('href'));
        let menuHeight_compensetion = document.querySelectorAll('header')[0].clientHeight;

        if (!block.classList.contains('d-none')) {
            let anchorBlock = document.querySelector('footer');
            let elementPosition = anchorBlock.offsetTop;
            let offsetPosition = elementPosition + anchorBlock.clientHeight - document.documentElement.clientHeight;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
            setTimeout(() => {
                block.classList.add('d-none');
            }, 1000)
        } else {
            block.classList.remove('d-none');
            let anchorBlock = block;
            let headerOffset = menuHeight_compensetion;
            let elementPosition = anchorBlock.offsetTop;
            let offsetPosition = elementPosition - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }

    privacyLink.forEach((link, index) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();
            openBlock(e.target);
        })
    });


    //Countup.js

    var __assign = (this && this.__assign) || function () {
        __assign = Object.assign || function (t) {
            for (var s, i = 1, n = arguments.length; i < n; i++) {
                s = arguments[i];
                for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                    t[p] = s[p];
            }
            return t;
        };
        return __assign.apply(this, arguments);
    };
// playground: stackblitz.com/edit/countup-typescript
    var CountUp = /** @class */ (function () {
        function CountUp(target, endVal, options) {
            var _this = this;
            this.target = target;
            this.endVal = endVal;
            this.options = options;
            this.version = '2.0.8';
            this.defaults = {
                startVal: 0,
                decimalPlaces: 0,
                duration: 2,
                useEasing: true,
                useGrouping: true,
                smartEasingThreshold: 999,
                smartEasingAmount: 333,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ''
            };
            this.finalEndVal = null; // for smart easing
            this.useEasing = true;
            this.countDown = false;
            this.error = '';
            this.startVal = 0;
            this.paused = true;
            this.count = function (timestamp) {
                if (!_this.startTime) {
                    _this.startTime = timestamp;
                }
                var progress = timestamp - _this.startTime;
                _this.remaining = _this.duration - progress;
                // to ease or not to ease
                if (_this.useEasing) {
                    if (_this.countDown) {
                        _this.frameVal = _this.startVal - _this.easingFn(progress, 0, _this.startVal - _this.endVal, _this.duration);
                    } else {
                        _this.frameVal = _this.easingFn(progress, _this.startVal, _this.endVal - _this.startVal, _this.duration);
                    }
                } else {
                    if (_this.countDown) {
                        _this.frameVal = _this.startVal - ((_this.startVal - _this.endVal) * (progress / _this.duration));
                    } else {
                        _this.frameVal = _this.startVal + (_this.endVal - _this.startVal) * (progress / _this.duration);
                    }
                }
                // don't go past endVal since progress can exceed duration in the last frame
                if (_this.countDown) {
                    _this.frameVal = (_this.frameVal < _this.endVal) ? _this.endVal : _this.frameVal;
                } else {
                    _this.frameVal = (_this.frameVal > _this.endVal) ? _this.endVal : _this.frameVal;
                }
                // decimal
                _this.frameVal = Number(_this.frameVal.toFixed(_this.options.decimalPlaces));
                // format and print value
                _this.printValue(_this.frameVal);
                // whether to continue
                if (progress < _this.duration) {
                    _this.rAF = requestAnimationFrame(_this.count);
                } else if (_this.finalEndVal !== null) {
                    // smart easing
                    _this.update(_this.finalEndVal);
                } else {
                    if (_this.callback) {
                        _this.callback();
                    }
                }
            };
            // default format and easing functions
            this.formatNumber = function (num) {
                var neg = (num < 0) ? '-' : '';
                var result, x1, x2, x3;
                result = Math.abs(num).toFixed(_this.options.decimalPlaces);
                result += '';
                var x = result.split('.');
                x1 = x[0];
                x2 = x.length > 1 ? _this.options.decimal + x[1] : '';
                if (_this.options.useGrouping) {
                    x3 = '';
                    for (var i = 0, len = x1.length; i < len; ++i) {
                        if (i !== 0 && (i % 3) === 0) {
                            x3 = _this.options.separator + x3;
                        }
                        x3 = x1[len - i - 1] + x3;
                    }
                    x1 = x3;
                }
                // optional numeral substitution
                if (_this.options.numerals && _this.options.numerals.length) {
                    x1 = x1.replace(/[0-9]/g, function (w) {
                        return _this.options.numerals[+w];
                    });
                    x2 = x2.replace(/[0-9]/g, function (w) {
                        return _this.options.numerals[+w];
                    });
                }
                return neg + _this.options.prefix + x1 + x2 + _this.options.suffix;
            };
            this.easeOutExpo = function (t, b, c, d) {
                return c * (-Math.pow(2, -10 * t / d) + 1) * 1024 / 1023 + b;
            };
            this.options = __assign(__assign({}, this.defaults), options);
            this.formattingFn = (this.options.formattingFn) ?
                this.options.formattingFn : this.formatNumber;
            this.easingFn = (this.options.easingFn) ?
                this.options.easingFn : this.easeOutExpo;
            this.startVal = this.validateValue(this.options.startVal);
            this.frameVal = this.startVal;
            this.endVal = this.validateValue(endVal);
            this.options.decimalPlaces = Math.max(0 || this.options.decimalPlaces);
            this.resetDuration();
            this.options.separator = String(this.options.separator);
            this.useEasing = this.options.useEasing;
            if (this.options.separator === '') {
                this.options.useGrouping = false;
            }
            this.el = (typeof target === 'string') ? document.getElementById(target) : target;
            if (this.el) {
                this.printValue(this.startVal);
            } else {
                this.error = '[CountUp] target is null or undefined';
            }
        }

        // determines where easing starts and whether to count down or up
        CountUp.prototype.determineDirectionAndSmartEasing = function () {
            var end = (this.finalEndVal) ? this.finalEndVal : this.endVal;
            this.countDown = (this.startVal > end);
            var animateAmount = end - this.startVal;
            if (Math.abs(animateAmount) > this.options.smartEasingThreshold) {
                this.finalEndVal = end;
                var up = (this.countDown) ? 1 : -1;
                this.endVal = end + (up * this.options.smartEasingAmount);
                this.duration = this.duration / 2;
            } else {
                this.endVal = end;
                this.finalEndVal = null;
            }
            if (this.finalEndVal) {
                this.useEasing = false;
            } else {
                this.useEasing = this.options.useEasing;
            }
        };
        // start animation
        CountUp.prototype.start = function (callback) {
            if (this.error) {
                return;
            }
            this.callback = callback;
            if (this.duration > 0) {
                this.determineDirectionAndSmartEasing();
                this.paused = false;
                this.rAF = requestAnimationFrame(this.count);
            } else {
                this.printValue(this.endVal);
            }
        };
        // pause/resume animation
        CountUp.prototype.pauseResume = function () {
            if (!this.paused) {
                cancelAnimationFrame(this.rAF);
            } else {
                this.startTime = null;
                this.duration = this.remaining;
                this.startVal = this.frameVal;
                this.determineDirectionAndSmartEasing();
                this.rAF = requestAnimationFrame(this.count);
            }
            this.paused = !this.paused;
        };
        // reset to startVal so animation can be run again
        CountUp.prototype.reset = function () {
            cancelAnimationFrame(this.rAF);
            this.paused = true;
            this.resetDuration();
            this.startVal = this.validateValue(this.options.startVal);
            this.frameVal = this.startVal;
            this.printValue(this.startVal);
        };
        // pass a new endVal and start animation
        CountUp.prototype.update = function (newEndVal) {
            cancelAnimationFrame(this.rAF);
            this.startTime = null;
            this.endVal = this.validateValue(newEndVal);
            if (this.endVal === this.frameVal) {
                return;
            }
            this.startVal = this.frameVal;
            if (!this.finalEndVal) {
                this.resetDuration();
            }
            this.finalEndVal = null;
            this.determineDirectionAndSmartEasing();
            this.rAF = requestAnimationFrame(this.count);
        };
        CountUp.prototype.printValue = function (val) {
            var result = this.formattingFn(val);
            if (this.el.tagName === 'INPUT') {
                var input = this.el;
                input.value = result;
            } else if (this.el.tagName === 'text' || this.el.tagName === 'tspan') {
                this.el.textContent = result;
            } else {
                this.el.innerHTML = result;
            }
        };
        CountUp.prototype.ensureNumber = function (n) {
            return (typeof n === 'number' && !isNaN(n));
        };
        CountUp.prototype.validateValue = function (value) {
            var newValue = Number(value);
            if (!this.ensureNumber(newValue)) {
                this.error = "[CountUp] invalid start or end value: " + value;
                return null;
            } else {
                return newValue;
            }
        };
        CountUp.prototype.resetDuration = function () {
            this.startTime = null;
            this.duration = Number(this.options.duration) * 1000;
            this.remaining = this.duration;
        };
        return CountUp;
    }());

    // Counter options and init

    const options = {
        separator: '',
    };
    let first_number = new CountUp('firstCounter', 200, options);
    let second_number = new CountUp('secondCounter', 350, options);
    let third_number = new CountUp('thirdCounter', 1, options);
    let fourth_number = new CountUp('fourthCounter', 2002, options);
    const optionsObserver = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2,
    }
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                first_number.start();
                second_number.start();
                third_number.start();
                fourth_number.start();
                observer.unobserve(experience);
            }
        })
    }, optionsObserver)
    let experience = document.querySelector('.experience__card-wrapper');
    observer.observe(experience);

});
